import { ThemeTitle } from 'components/ui/ThemeTitle';
import { Container, Row, Col, Modal } from 'components/ui';
import styled from 'styled-components';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { faHome } from 'react-bootstrap-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Breadcrumb from 'components/layout/Breadcrumb.js';
import { useTranslation } from 'next-i18next';

const Startseite = () => {
	const { t } = useTranslation();
	const startpageBoxes = [
		{
			color: '#ff9b0e',
			title: t('common.impfschaeden'),
			img: 'Header-Bild_Impschaeden_02.jpg.png',
			text1: t('startseite.impfschaden.erfassen'),
			text2: t('startseite.impfschaden.statistik'),
			link: '/' + t('routing.impfschaden'),
		},
		{
			color: '#cf5959',
			title: t('navigation.mobilfunk_und_krebs'),
			img: 'Header-Bild_Mobilfunk_02.jpg.png',
			text1: t('startseite.mobilfunk.erfassen'),
			text2: t('startseite.mobilfunk.statistik'),
			link: 'https://v1.vetopedia.org/mobilfunk-krebs',
		},
		{
			color: '#83e3bd',
			title: t('common.zensurkioske'),
			img: 'Header-Bild_Zensurkiosk_01.jpg',
			text1: t('startseite.kiosk.erfassen'),
			text2: t('startseite.kiosk.statistik'),
			link: '/' + t('routing.zensurkiosk'),
		},
		{
			color: '#4195e1',
			title: t('navigation.pharmamedizinopfer'),
			img: 'Header-Bild_Medizinopfer_02.jpg.png',
			text1: t('startseite.pharma.erfassen'),
			text2: t('startseite.pharma.statistik'),
			link: 'https://v1.vetopedia.org/pharma',
		},
		{
			color: '#604f8b',
			title: t('navigation.medienverleumdungen'),
			img: 'Header-Bild_Medien_02.jpg.png',
			text1: t('startseite.medien.erfassen'),
			text2: t('startseite.medien.statistik'),
			link: 'https://v1.vetopedia.org/medienverleumdung',
		},
	];
	return (
		<div>
			<div className='position-relative mx-n4 mt-n4 mb-4'>
				<img
					src='/img/header_vetopediafreieenzyklopaedie.jpg'
					className='w-100'
					alt='vetopedia die freie enzyklopädie der gegenstimmen'
				/>
				<h4 className='position-absolute w-100 fw-normal text-center bottom-0 text-white m-0 mb-2'>
					{t('common.vetpediaenzyklopaedie')}
				</h4>
			</div>
			<Breadcrumb />
			<Row>
				{startpageBoxes.map((box, index) => {
					return (
						<Col key={index} xs={12} sm={6} md={4}>
							<a
								href={box.link}
								className='h-100 d-flex flex-column'
								style={{ textDecoration: 'none', color: 'initial' }}
							>
								<div className='card mb-3 flex-grow-1'>
									<div className='card-img-topbg-white'>
										<div className='p-2 text-center'>
											<h3
												className='m-0 text-uppercase'
												style={{ hyphens: 'auto' }}
											>
												<span dangerouslySetInnerHTML={{ __html: box.title }} />
											</h3>
										</div>
										<div
											className='w-100'
											style={{
												height: '4px',
												backgroundColor: box.color,
											}}
										>
											{' '}
										</div>
										<img
											className='w-100'
											src={`/img/${box.img}`}
											alt={box.title}
										/>
									</div>
									<div className='card-body d-flex flex-column'>
										<div className='mb-3 flex-grow-1'>
											<h2 className='h5'>
												<span>{box.text1}</span>
											</h2>
											<hr />
											<p>
												<i>{box.text2}</i>
											</p>
										</div>
										<div>
											<Button
												className='mb-1 text-white w-100 text-start fs-5'
												style={{
													backgroundColor: box.color,
													border: 'none',
												}}
											>
												<FontAwesomeIcon icon={faArrowRight} className='me-2' />
												{t('common.mehr_erfahren')}
											</Button>
										</div>
									</div>
								</div>
							</a>
						</Col>
					);
				})}
			</Row>
		</div>
	);
};

Startseite.pageTheme = {
	themePrimary: '#604F8B',
};

export default Startseite;